import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { RobotDateRangeInterface } from "src/app/shared/interfaces/date-range.interface";
import { LogsFiltersStateInterface } from "./interfaces/logs-filters-state.interface";

type TAbstractState = LogsFiltersStateInterface<ReadonlyArray<string | number>>;

@Injectable()
export abstract class AbstractFiltersStore<
  TState extends TAbstractState = TAbstractState,
  TCategories extends ReadonlyArray<string> | ReadonlyArray<number> = ReadonlyArray<string> | ReadonlyArray<number>,
> {
  abstract state$: BehaviorSubject<TState>;

  getState$(): Observable<TState> {
    return this.state$.asObservable();
  }

  updateDevice(deviceId: string): void {
    this.state$.next({
      ...this.state$.value,
      device: deviceId,
    });
  }

  updateDateRange(dateRange: RobotDateRangeInterface): void {
    this.state$.next({
      ...this.state$.value,
      start: dateRange.dateFrom.toISOString(),
      end: dateRange.dateTo.toISOString(),
      robotTime: dateRange.robotTime,
    });
  }

  updateLimit(limit: number): void {
    this.state$.next({
      ...this.state$.value,
      limit,
    });
  }

  updateCategories(categories: TCategories): void {
    this.state$.next({
      ...this.state$.value,
      categories,
    });
  }

  update(partialState: Partial<TState>): void {
    if (Object.keys(partialState).length === 0) return;
    const oldState = this.state$.value;

    if (Object.values(partialState).includes((v: unknown) => v === undefined)) {
      throw new Error("Undefined value in partialState");
    }
    if (!Object.keys(partialState).every(k => k in oldState)) {
      throw new Error("Unknown key in partialState");
    }

    this.state$.next({
      ...oldState,
      ...partialState,
    });
  }

  resetCategories(): void {
    this.state$.next({
      ...this.state$.value,
      categories: [],
    });
  }

  updateSearch(search: string): void {
    this.state$.next({
      ...this.state$.value,
      search,
    });
  }
}
